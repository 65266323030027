import ApexCharts from 'apexcharts';

document.addEventListener('DOMContentLoaded', () => {
    initShare();
});

function initShare() {
    const isShare = document.querySelector('#share');
    if (!isShare) {
        return;
    }

    const share = new Share();
    share.init();
}

class Share {
    constructor() {
        this.chart = null;
    }

    init() {
        const options = {
            chart: {
                width: 450,
                type: "pie",
            },
            labels: [],
            series: [],
            legend: {
                position: "right",
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 0,
            },
        };

        this.chart = new ApexCharts(document.querySelector("#pie"), options);
        this.chart.render();

        this.loadChartData();
    }

    async loadChartData() {
        try {
            const response = await fetch($('#pie').data('link')); // Fetch data from the API
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.data.labels && data.data.series) {
                const colors = this.generateColors('#007deb', '#c1e1ff', data.data.labels.length);

                this.chart.updateOptions({
                    labels: data.data.labels,
                    colors: colors, // Set dynamically generated colors
                });
                this.chart.updateSeries(data.data.series);
            } else {
                console.error('Invalid data from API');
            }
        } catch (error) {
            console.error('Error loading chart data:', error);
        }
    }

    generateColors(startColor, endColor, steps) {
        const start = this.hexToRgb(startColor);
        const end = this.hexToRgb(endColor);
        const colors = [];

        for (let i = 0; i < steps; i++) {
            const r = Math.round(start.r + ((end.r - start.r) * i) / (steps - 1));
            const g = Math.round(start.g + ((end.g - start.g) * i) / (steps - 1));
            const b = Math.round(start.b + ((end.b - start.b) * i) / (steps - 1));
            colors.push(`rgb(${r}, ${g}, ${b})`);
        }

        return colors;
    }

    hexToRgb(hex) {
        const bigint = parseInt(hex.replace('#', ''), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    }
}
